import { api } from '@/api/api.js';
import { switchLanguage } from '@/i18n.js';
import { mapObject } from '@/utils.js';
import { StorageSerializers, useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useOptionsStore } from '@/stores/options-store.js';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        user: useLocalStorage('user', null, {
            serializer: StorageSerializers.object,
        }),
        impersonator: useLocalStorage('impersonator', null, {
            serializer: StorageSerializers.object,
        }),
        accessToken: useLocalStorage('accessToken', null, {
            serializer: StorageSerializers.string,
        }),
        impersonatorAccessToken: useLocalStorage('impersonatorAccessToken', null, {
            serializer: StorageSerializers.string,
        }),
        userLocale: useLocalStorage('userLocale', 'en-US'),
        apiErrors: {},
        toastMessages: [],
        tableFilters: useLocalStorage('tableFilters', new Map(), {
            serializer: StorageSerializers.map,
        }),
        inMaintenanceMode: false,
        environment: import.meta.env.VITE_RELEASE_STAGE,
        redirectTo: '/home',
    }),

    getters: {
        isAuthenticated: state => Boolean(state.accessToken),
        isProduction: state => Boolean(state.environment === 'production'),
        isStaging: state => Boolean(state.environment === 'staging'),
        isLocal: state => Boolean(state.environment === 'local'),
        permissions: state => state.user?.permissions ?? [],
        role: state => state.user?.role?.id ?? null,
        apiErrorMessages: state => mapObject(state.apiErrors, (([key, value]) => [key, value.join(' ')])),
    },

    actions: {
        async login(payload) {
            const options = useOptionsStore();
            const {
                data: {
                    user,
                    token,
                },
            } = await api.post('/authentication/login', payload);

            this.setAuthUser(user);
            this.setAccessToken(token);
            this.resetTableFilters();
            await options.fetchMetaData();
        },

        async logout() {
            await api.post('/authentication/logout');

            this.clearAuthUserState();
        },

        async impersonateUser(id) {
            const {
                data: {
                    user,
                    token,
                    impersonator,
                    impersonatorToken,
                },
            } = await api.post('/authentication/impersonate', {
                id,
            });

            this.setAuthUser(user);
            this.setImpersonator(impersonator);
            this.setImpersonatorAccessToken(impersonatorToken);
            this.setAccessToken(token);
            this.resetTableFilters();
        },

        async leaveImpersonating() {
            const {
                data: {
                    user,
                    token,
                },
            } = await api.post('/authentication/impersonate/leave', {
                token: this.impersonatorAccessToken,
            });

            this.user = user;
            this.setAccessToken(token);
            this.setImpersonator(null);
            this.setImpersonatorAccessToken(null);
            this.resetTableFilters();
        },

        async requestUsername(payload) {
            await api.post('/authentication/usernames-request', payload);
        },

        async requestPasswordReset(payload) {
            await api.post('/authentication/password-reset-request', payload);
        },

        async resetPassword(payload) {
            const {
                data: {
                    token,
                    user,
                },
            } = await api.post('/authentication/password-reset', payload);

            this.setAccessToken(token);
            this.setAuthUser(user);
        },

        async activateAccount(payload) {
            const {
                data: {
                    token,
                    user,
                },
            } = await api.post('/authentication/account-activation', payload);

            this.setAccessToken(token);
            this.setAuthUser(user);
        },

        async refreshToken() {
            const { data: { token } } = await api.post('/authentication/refresh');

            this.setAccessToken(token);
        },

        async setUserLanguage(language) {
            this.userLocale = language;

            await switchLanguage(language);
        },

        setAuthUser(user) {
            this.user = user;
        },

        setImpersonator(user) {
            this.impersonator = user;
        },

        setAccessToken(token) {
            this.accessToken = token;
        },

        setImpersonatorAccessToken(token) {
            this.impersonatorAccessToken = token;
        },

        clearAuthUserState() {
            this.user = null;
            this.accessToken = null;
            this.impersonator = null;
            this.impersonatorAccessToken = null;
        },

        setRedirectTo(redirectTo) {
            this.redirectTo = redirectTo;
        },

        setMaintenanceMode(inMaintenanceMode) {
            this.inMaintenanceMode = inMaintenanceMode;
        },

        setApiErrors(errors) {
            this.apiErrors = errors;
        },

        clearApiErrors() {
            this.apiErrors = {};
        },

        addToastMessage(toast) {
            this.toastMessages.push(toast);
        },

        dismissToastMessage(index) {
            this.toastMessages.splice(index, 1);
        },

        initialiseTableFilter(tableId) {
            if (!this.tableFilters.has(tableId)) {
                this.tableFilters.set(tableId, {});
            }
        },

        resetTableFilters() {
            this.tableFilters = new Map();
        },

        setTableFilter(tableId, value) {
            this.tableFilters.set(tableId, {
                ...(this.tableFilters.has(tableId) ? this.tableFilters.get(tableId) : {}),
                ...value,
            });
        },

        clearTableFilters(tableId) {
            this.tableFilters.set(tableId, {});
        },

        setUnreadNotificationCount(count) {
            this.user.unreadNotificationCount = count;
        },
    },
});
