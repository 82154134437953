import { useAuth } from '@/composables/use-auth.js';
import { switchLanguage } from '@/i18n.js';
import { useGlobalStore } from '@/stores/stores.js';
import { useOptionsStore } from '@/stores/options-store.js';

function authMiddleware(to, from, next) {

    const store = useGlobalStore();
    const { isAuthenticated } = store;

    if (to.matched.some(record => record.meta.auth) && !isAuthenticated) {

        next('/login');

        return;
    }

    // Redirect to home page if already logged in
    if (to.path === '/login' && isAuthenticated) {
        next('/');

        return;
    }

    next();
}

function permissionMiddleware(to, from, next) {
    const { hasPermission } = useAuth();

    if (to.matched.some(({ meta }) => Boolean(meta?.permissions) ? !meta.permissions.every(hasPermission) : false)) {
        next({
            name: 'unauthorized',
        });

        return;
    }

    next();
}

async function translationsMiddleware(to, from, next) {
    const { userLocale } = useGlobalStore();

    await switchLanguage(userLocale);
    next();
}

async function loadMetaDataMiddleware(to, from, next) {
    const {
        inMaintenanceMode,
        user,
    } = useGlobalStore();

    const options = useOptionsStore();

    if (!inMaintenanceMode && user && !options.metaDataLoaded) {
        await options.fetchMetaData();
    }

    next();
}

export {
    authMiddleware,
    permissionMiddleware,
    translationsMiddleware,
    loadMetaDataMiddleware,
};
