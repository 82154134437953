<template>
    <AsicsForm
        class="pt-4 md:pt-8"
        @formSubmit="logIn"
    >
        <template #header>
            <h2 class="py-3">
                {{ $t('login.title') }}
            </h2>
        </template>
        <AsicsInput
            id="username"
            v-model="payload.username"
            :label="$t('general.username')"
            maxlength="150"
            validation-rules="required|minlength:4|maxlength:150"
        />
        <AsicsInput
            id="password"
            v-model="payload.password"
            type="password"
            :label="$t('general.password')"
            maxlength="150"
            validation-rules="required|minlength:8|maxlength:150"
            container-class="h-[5.125rem] mb-0"
        />

        <div
            v-if="errorMessage"
            class="mb-3 text-orange-dark"
        >
            {{ errorMessage }}
        </div>

        <template #footer>
            <div class="flex justify-end mt-3 mb-8">
                <Link
                    :link-text="$t('login.link.resetPassword')"
                    link-url="/password-reset-request"
                    class="mr-4"
                />
                <Link
                    :link-text="$t('login.link.forgotUsername')"
                    link-url="/forgotten-username"
                />
            </div>
            <AsicsButton
                :text="$t('login.buttonSubmit')"
                type="submit"
                class="w-full"
                :disabled="submitButton.inProgress"
            >
                <Icon
                    v-if="submitButton.inProgress"
                    name="spinner"
                    spin
                />
            </AsicsButton>
        </template>
    </AsicsForm>

    <footer class="flex items-center justify-center text-sm mt-7 px-0">
        <DropDownSelect
            v-model="selectedUserLanguage"
            :flat-style="true"
            :options="userLanguageOptions"
            class="text-secondary border-0"
            required
            @onChange="handleLanguageChange"
        />
    </footer>
</template>

<script setup>
import AsicsButton from '@/components/common/asics-button/AsicsButton.vue';
import AsicsForm from '@/components/common/asics-form/AsicsForm.vue';
import AsicsInput from '@/components/common/asics-input/AsicsInput.vue';
import DropDownSelect from '@/components/common/drop-down-select/DropDownSelect.vue';
import Icon from '@/components/common/icon/Icon.vue';
import Link from '@/components/common/link/Link.vue';
import { useAuth } from '@/composables/use-auth';
import { getLanguages } from '@/modules/unauthenticated/login/login.api';
import { formatLanguageName } from '@/utils';
import { storeToRefs } from 'pinia';
import {
    onMounted, reactive, ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const {
    store,
    submitButton,
} = useAuth();

const { userLocale: selectedUserLanguage } = storeToRefs(store);
const userLanguageOptions = reactive([]);
const errorMessage = ref(null);
const payload = reactive({
    username: null,
    password: null,
});

const handleLanguageChange = language => {
    store.setUserLanguage(language);
};

const getLanguageOptions = async () => {
    const { data: languages } = await getLanguages();

    Object.assign(userLanguageOptions, languages.map(language => {
        const [languageCode, regionCode] = language.split('-');

        return {
            value: language,
            text: `${formatLanguageName(language, languageCode)} (${regionCode})`,
            translated: true,
        };
    }));
};

const logIn = async () => {
    submitButton.inProgress = true;
    errorMessage.value = null;

    try {
        await store.login(payload);
        await router.push(store.redirectTo);
    } catch (error) {
        errorMessage.value = error?.message;
    } finally {
        submitButton.inProgress = false;
    }
};

onMounted(async () => {
    await getLanguageOptions();

    if (route.redirectedFrom?.fullPath) {
        store.setRedirectTo(route.redirectedFrom.fullPath);
    }

});
</script>
